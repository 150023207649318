/**
* This file was automatically generated by @cosmwasm/ts-codegen@0.24.0.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the @cosmwasm/ts-codegen generate command to regenerate this file.
*/

import { UseQueryOptions, QueryClient } from "@tanstack/react-query";
import { Uint128, Config, Boolean, NullableUint128, NullableArrayOfPossibleShit } from "@dao-dao/types/contracts/ShitStrap";
import { ShitStrapQueryClient } from '../../../contracts/ShitStrap'
import { indexerQueries } from "../indexer";
import { getCosmWasmClientForChainId } from "@dao-dao/utils";
export const shitStrapQueryKeys = {
  contract: ([{
    contract: "shitStrap"
  }] as const),
  address: (chainId: string, contractAddress: string) => ([{
    ...shitStrapQueryKeys.contract[0],
    address: contractAddress
  }] as const),
  config: (chainId: string, contractAddress: string, args?: Record<string, unknown>) => ([{
    ...shitStrapQueryKeys.address(chainId, contractAddress)[0],
    method: "config",
    args
  }] as const),
  shitPile: (chainId: string, contractAddress: string, args?: Record<string, unknown>) => ([{
    ...shitStrapQueryKeys.address(chainId, contractAddress)[0],
    method: "shit_pile",
    args
  }] as const),
  fullOfShit: (chainId: string, contractAddress: string, args?: Record<string, unknown>) => ([{
    ...shitStrapQueryKeys.address(chainId, contractAddress)[0],
    method: "full_of_shit",
    args
  }] as const),
  shitRate: (chainId: string, contractAddress: string, args?: Record<string, unknown>) => ([{
    ...shitStrapQueryKeys.address(chainId, contractAddress)[0],
    method: "shit_rate",
    args
  }] as const),
  shitRates: (chainId: string, contractAddress: string, args?: Record<string, unknown>) => ([{
    ...shitStrapQueryKeys.address(chainId, contractAddress)[0],
    method: "shit_rates",
    args
  }] as const)
};
export const shitStrapQueries = {
  config: <TData = Config,>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options
    }: ShitStrapConfigQuery<TData>): UseQueryOptions<Config, Error, TData> => ({
      queryKey: shitStrapQueryKeys.config(chainId, contractAddress),
      queryFn: async () => {
        // try {
        //   // Attempt to fetch data from the indexer.
        //   return await queryClient.fetchQuery(
        //     indexerQueries.queryContract(queryClient, {
        //       chainId,
        //       contractAddress: contractAddress,
        //       formula: 'shitstrap/config'
        //     })
        //   )
        // } catch (error) {
        //   console.error(error)
        // }

        // If indexer query fails, fallback to contract query
        return new ShitStrapQueryClient(
          await getCosmWasmClientForChainId(chainId),
          contractAddress
        ).config()
      },
      ...options,
      enabled: !!contractAddress && (options?.enabled != undefined ? options.enabled : true)
    }),
  shitPile: <TData = Uint128,>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options
    }: ShitStrapShitPileQuery<TData>): UseQueryOptions<Uint128, Error, TData> => ({
      queryKey: shitStrapQueryKeys.shitPile(chainId, contractAddress),
      queryFn: async () => {
        try {
          // Attempt to fetch data from the indexer.
          return await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress: contractAddress,
              formula: 'shitstrap/shitPile'
            })
          )
        } catch (error) {
          console.error(error)
        }

        // If indexer query fails, fallback to contract query
        return new ShitStrapQueryClient(
          await getCosmWasmClientForChainId(chainId),
          contractAddress
        ).shitPile()
      },
      ...options,
      enabled: !!contractAddress && (options?.enabled != undefined ? options.enabled : true)
    }),
  fullOfShit: <TData = Boolean,>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options
    }: ShitStrapFullOfShitQuery<TData>): UseQueryOptions<Boolean, Error, TData> => ({
      queryKey: shitStrapQueryKeys.fullOfShit(chainId, contractAddress),
      queryFn: async () => {
        // try {
        //   // Attempt to fetch data from the indexer.
        //   return await queryClient.fetchQuery(
        //     indexerQueries.queryContract(queryClient, {
        //       chainId,
        //       contractAddress: contractAddress,
        //       formula: 'shitstrap/fullOfShit'
        //     })
        //   )
        // } catch (error) {
        //   console.error(error)
        // }

        // If indexer query fails, fallback to contract query
        return new ShitStrapQueryClient(
          await getCosmWasmClientForChainId(chainId),
          contractAddress
        ).fullOfShit()
      },
      ...options,
      enabled: !!contractAddress && (options?.enabled != undefined ? options.enabled : true)
    }),
  shitRate: <TData = NullableUint128,>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      args,
      options
    }: ShitStrapShitRateQuery<TData>): UseQueryOptions<NullableUint128, Error, TData> => ({
      queryKey: shitStrapQueryKeys.shitRate(chainId, contractAddress, args),
      queryFn: async () => {
        try {
          // Attempt to fetch data from the indexer.
          return await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress: contractAddress,
              formula: 'shitstrap/shitRate'
            })
          )
        } catch (error) {
          console.error(error)
        }

        // If indexer query fails, fallback to contract query
        return new ShitStrapQueryClient(
          await getCosmWasmClientForChainId(chainId),
          contractAddress
        ).shitRate({ asset: args.asset })
      },
      ...options,
      enabled: !!contractAddress && (options?.enabled != undefined ? options.enabled : true)
    }),
  shitRates: <TData = NullableArrayOfPossibleShit,>(
    queryClient: QueryClient,
    {
      chainId,
      contractAddress,
      options
    }: ShitStrapShitRatesQuery<TData>): UseQueryOptions<NullableArrayOfPossibleShit, Error, TData> => ({
      queryKey: shitStrapQueryKeys.shitRates(chainId, contractAddress),
      queryFn: async () => {
        try {
          // Attempt to fetch data from the indexer.
          return await queryClient.fetchQuery(
            indexerQueries.queryContract(queryClient, {
              chainId,
              contractAddress: contractAddress,
              formula: 'shitstrap/shitRates'
            })
          )
        } catch (error) {
          console.error(error)
        }

        // If indexer query fails, fallback to contract query
        return new ShitStrapQueryClient(
          await getCosmWasmClientForChainId(chainId),
          contractAddress
        ).shitRates()
      },
      ...options,
      enabled: !!contractAddress && (options?.enabled != undefined ? options.enabled : true)
    })
};


export interface ShitStrapReactQuery<TResponse, TData = TResponse> {
  chainId: string,
  contractAddress: string;
  options?: Omit<UseQueryOptions<TResponse, Error, TData>, "'queryKey' | 'queryFn' | 'initialData'"> & {
    initialData?: undefined;
  };
}
export interface ShitStrapShitRatesQuery<TData> extends ShitStrapReactQuery<NullableArrayOfPossibleShit, TData> { }
export interface ShitStrapShitRateQuery<TData> extends ShitStrapReactQuery<NullableUint128, TData> {
  args: {
    asset: string;
  };
}


export interface ShitStrapFullOfShitQuery<TData> extends ShitStrapReactQuery<Boolean, TData> { }
export interface ShitStrapShitPileQuery<TData> extends ShitStrapReactQuery<Uint128, TData> { }
export interface ShitStrapConfigQuery<TData> extends ShitStrapReactQuery<Config, TData> { }
// export interface ShitStrapRefundShitterMutation {
//   client: ShitStrapClient;
//   args?: {
//     fee?: number | StdFee | "auto";
//     memo?: string;
//     funds?: Coin[];
//   };
// }
// export interface ShitStrapReceiveMutation {
//   client: ShitStrapClient;
//   msg: {
//     amount: Uint128;
//     msg: Binary;
//     sender: string;
//   };
//   args?: {
//     fee?: number | StdFee | "auto";
//     memo?: string;
//     funds?: Coin[];
//   };
// }

// export interface ShitStrapFlushMutation {
//   client: ShitStrapClient;
//   args?: {
//     fee?: number | StdFee | "auto";
//     memo?: string;
//     funds?: Coin[];
//   };
// }
// export interface ShitStrapShitStrapMutation {
//   client: ShitStrapClient;
//   msg: {
//     shit: AssetUnchecked;
//   };
//   args?: {
//     fee?: number | StdFee | "auto";
//     memo?: string;
//     funds?: Coin[];
//   };
// }
