/**
* This file was automatically generated by @cosmwasm/ts-codegen@0.24.0.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the @cosmwasm/ts-codegen generate command to regenerate this file.
*/

import { CosmWasmClient, SigningCosmWasmClient, ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { Coin, StdFee } from "@cosmjs/amino";
import { Uint128, Binary, AssetUnchecked,  Config, Boolean, NullableUint128, NullableArrayOfPossibleShit } from "@dao-dao/types/contracts/ShitStrap";
export interface ShitStrapReadOnlyInterface {
  contractAddress: string;
  config: () => Promise<Config>;
  shitPile: () => Promise<Uint128>;
  fullOfShit: () => Promise<Boolean>;
  shitRate: ({
    asset
  }: {
    asset: string;
  }) => Promise<NullableUint128>;
  shitRates: () => Promise<NullableArrayOfPossibleShit>;
}
export class ShitStrapQueryClient implements ShitStrapReadOnlyInterface {
  client: CosmWasmClient;
  contractAddress: string;

  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client;
    this.contractAddress = contractAddress;
    this.config = this.config.bind(this);
    this.shitPile = this.shitPile.bind(this);
    this.fullOfShit = this.fullOfShit.bind(this);
    this.shitRate = this.shitRate.bind(this);
    this.shitRates = this.shitRates.bind(this);
  }

  config = async (): Promise<Config> => {
    return this.client.queryContractSmart(this.contractAddress, {
      config: {}
    });
  };
  shitPile = async (): Promise<Uint128> => {
    return this.client.queryContractSmart(this.contractAddress, {
      shit_pile: {}
    });
  };
  fullOfShit = async (): Promise<Boolean> => {
    return this.client.queryContractSmart(this.contractAddress, {
      full_of_shit: {}
    });
  };
  shitRate = async ({
    asset
  }: {
    asset: string;
  }): Promise<NullableUint128> => {
    return this.client.queryContractSmart(this.contractAddress, {
      shit_rate: {
        asset
      }
    });
  };
  shitRates = async (): Promise<NullableArrayOfPossibleShit> => {
    return this.client.queryContractSmart(this.contractAddress, {
      shit_rates: {}
    });
  };
}
export interface ShitStrapInterface extends ShitStrapReadOnlyInterface {
  contractAddress: string;
  sender: string;
  shitStrap: ({
    shit
  }: {
    shit: AssetUnchecked;
  }, fee?: number | StdFee | "auto", memo?: string, funds?: Coin[]) => Promise<ExecuteResult>;
  flush: (fee?: number | StdFee | "auto", memo?: string, funds?: Coin[]) => Promise<ExecuteResult>;
  receive: ({
    amount,
    msg,
    sender
  }: {
    amount: Uint128;
    msg: Binary;
    sender: string;
  }, fee?: number | StdFee | "auto", memo?: string, funds?: Coin[]) => Promise<ExecuteResult>;
  refundShitter: (fee?: number | StdFee | "auto", memo?: string, funds?: Coin[]) => Promise<ExecuteResult>;
}
export class ShitStrapClient extends ShitStrapQueryClient implements ShitStrapInterface {
  client: SigningCosmWasmClient;
  sender: string;
  contractAddress: string;

  constructor(client: SigningCosmWasmClient, sender: string, contractAddress: string) {
    super(client, contractAddress);
    this.client = client;
    this.sender = sender;
    this.contractAddress = contractAddress;
    this.shitStrap = this.shitStrap.bind(this);
    this.flush = this.flush.bind(this);
    this.receive = this.receive.bind(this);
    this.refundShitter = this.refundShitter.bind(this);
  }

  shitStrap = async ({
    shit
  }: {
    shit: AssetUnchecked;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      shit_strap: {
        shit
      }
    }, fee, memo, funds);
  };
  flush = async (fee: number | StdFee | "auto" = "auto", memo?: string, funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      flush: {}
    }, fee, memo, funds);
  };
  receive = async ({
    amount,
    msg,
    sender
  }: {
    amount: Uint128;
    msg: Binary;
    sender: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      receive: {
        amount,
        msg,
        sender
      }
    }, fee, memo, funds);
  };
  refundShitter = async (fee: number | StdFee | "auto" = "auto", memo?: string, funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      refund_shitter: {}
    }, fee, memo, funds);
  };
}