/**
* This file was automatically generated by @cosmwasm/ts-codegen@0.24.0.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the @cosmwasm/ts-codegen generate command to regenerate this file.
*/

import { CosmWasmClient, SigningCosmWasmClient, ExecuteResult } from "@cosmjs/cosmwasm-stargate";
import { Coin, StdFee } from "@cosmjs/amino";
import { ShitstrapInstantiateMsg, Uint64, ArrayOfShitstrapContract, OwnershipForAddr } from "@dao-dao/types/contracts/ShitstrapFactory";
export interface ShitStrapFactoryReadOnlyInterface {
  contractAddress: string;
  listShitstrapContracts: ({
    limit,
    startAfter
  }: {
    limit?: number;
    startAfter?: string;
  }) => Promise<ArrayOfShitstrapContract>;
  listShitstrapContractsReverse: ({
    limit,
    startBefore
  }: {
    limit?: number;
    startBefore?: string;
  }) => Promise<ArrayOfShitstrapContract>;
  listShitstrapContractsByInstantiator: ({
    instantiator,
    limit,
    startAfter
  }: {
    instantiator: string;
    limit?: number;
    startAfter?: string;
  }) => Promise<ArrayOfShitstrapContract>;
  listShitstrapContractsByInstantiatorReverse: ({
    instantiator,
    limit,
    startBefore
  }: {
    instantiator: string;
    limit?: number;
    startBefore?: string;
  }) => Promise<ArrayOfShitstrapContract>;
  listShitstrapContractsByToken: ({
    limit,
    recipient,
    startAfter
  }: {
    limit?: number;
    recipient: string;
    startAfter?: string;
  }) => Promise<ArrayOfShitstrapContract>;
  listShitstrapContractsByTokenReverse: ({
    limit,
    recipient,
    startBefore
  }: {
    limit?: number;
    recipient: string;
    startBefore?: string;
  }) => Promise<ArrayOfShitstrapContract>;
  ownership: () => Promise<OwnershipForAddr>;
  codeId: () => Promise<Uint64>;
}
export class ShitStrapFactoryQueryClient implements ShitStrapFactoryReadOnlyInterface {
  client: CosmWasmClient;
  contractAddress: string;

  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client;
    this.contractAddress = contractAddress;
    this.listShitstrapContracts = this.listShitstrapContracts.bind(this);
    this.listShitstrapContractsReverse = this.listShitstrapContractsReverse.bind(this);
    this.listShitstrapContractsByInstantiator = this.listShitstrapContractsByInstantiator.bind(this);
    this.listShitstrapContractsByInstantiatorReverse = this.listShitstrapContractsByInstantiatorReverse.bind(this);
    this.listShitstrapContractsByToken = this.listShitstrapContractsByToken.bind(this);
    this.listShitstrapContractsByTokenReverse = this.listShitstrapContractsByTokenReverse.bind(this);
    this.ownership = this.ownership.bind(this);
    this.codeId = this.codeId.bind(this);
  }

  listShitstrapContracts = async ({
    limit,
    startAfter
  }: {
    limit?: number;
    startAfter?: string;
  }): Promise<ArrayOfShitstrapContract> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_shitstrap_contracts: {
        limit,
        start_after: startAfter
      }
    });
  };
  listShitstrapContractsReverse = async ({
    limit,
    startBefore
  }: {
    limit?: number;
    startBefore?: string;
  }): Promise<ArrayOfShitstrapContract> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_shitstrap_contracts_reverse: {
        limit,
        start_before: startBefore
      }
    });
  };
  listShitstrapContractsByInstantiator = async ({
    instantiator,
    limit,
    startAfter
  }: {
    instantiator: string;
    limit?: number;
    startAfter?: string;
  }): Promise<ArrayOfShitstrapContract> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_shitstrap_contracts_by_instantiator: {
        instantiator,
        limit,
        start_after: startAfter
      }
    });
  };
  listShitstrapContractsByInstantiatorReverse = async ({
    instantiator,
    limit,
    startBefore
  }: {
    instantiator: string;
    limit?: number;
    startBefore?: string;
  }): Promise<ArrayOfShitstrapContract> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_shitstrap_contracts_by_instantiator_reverse: {
        instantiator,
        limit,
        start_before: startBefore
      }
    });
  };
  listShitstrapContractsByToken = async ({
    limit,
    recipient,
    startAfter
  }: {
    limit?: number;
    recipient: string;
    startAfter?: string;
  }): Promise<ArrayOfShitstrapContract> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_shitstrap_contracts_by_token: {
        limit,
        recipient,
        start_after: startAfter
      }
    });
  };
  listShitstrapContractsByTokenReverse = async ({
    limit,
    recipient,
    startBefore
  }: {
    limit?: number;
    recipient: string;
    startBefore?: string;
  }): Promise<ArrayOfShitstrapContract> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_shitstrap_contracts_by_token_reverse: {
        limit,
        recipient,
        start_before: startBefore
      }
    });
  };
  ownership = async (): Promise<OwnershipForAddr> => {
    return this.client.queryContractSmart(this.contractAddress, {
      ownership: {}
    });
  };
  codeId = async (): Promise<Uint64> => {
    return this.client.queryContractSmart(this.contractAddress, {
      code_id: {}
    });
  };
}
export interface ShitStrapFactoryInterface extends ShitStrapFactoryReadOnlyInterface {
  contractAddress: string;
  sender: string;
  createNativeShitStrapContract: ({
    instantiateMsg,
    label
  }: {
    instantiateMsg: ShitstrapInstantiateMsg;
    label: string;
  }, fee?: number | StdFee | "auto", memo?: string, funds?: Coin[]) => Promise<ExecuteResult>;
  updateCodeId: ({
    shitstrapCodeId
  }: {
    shitstrapCodeId: number;
  }, fee?: number | StdFee | "auto", memo?: string, funds?: Coin[]) => Promise<ExecuteResult>;
  updateOwnership: (fee?: number | StdFee | "auto", memo?: string, funds?: Coin[]) => Promise<ExecuteResult>;
}
export class ShitStrapFactoryClient extends ShitStrapFactoryQueryClient implements ShitStrapFactoryInterface {
  client: SigningCosmWasmClient;
  sender: string;
  contractAddress: string;

  constructor(client: SigningCosmWasmClient, sender: string, contractAddress: string) {
    super(client, contractAddress);
    this.client = client;
    this.sender = sender;
    this.contractAddress = contractAddress;
    this.createNativeShitStrapContract = this.createNativeShitStrapContract.bind(this);
    this.updateCodeId = this.updateCodeId.bind(this);
    this.updateOwnership = this.updateOwnership.bind(this);
  }

  createNativeShitStrapContract = async ({
    instantiateMsg,
    label
  }: {
    instantiateMsg: ShitstrapInstantiateMsg;
    label: string;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      create_native_shit_strap_contract: {
        instantiate_msg: instantiateMsg,
        label
      }
    }, fee, memo, funds);
  };
  updateCodeId = async ({
    shitstrapCodeId
  }: {
    shitstrapCodeId: number;
  }, fee: number | StdFee | "auto" = "auto", memo?: string, funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      update_code_id: {
        shitstrap_code_id: shitstrapCodeId
      }
    }, fee, memo, funds);
  };
  updateOwnership = async (fee: number | StdFee | "auto" = "auto", memo?: string, funds?: Coin[]): Promise<ExecuteResult> => {
    return await this.client.execute(this.sender, this.contractAddress, {
      update_ownership: {}
    }, fee, memo, funds);
  };
}